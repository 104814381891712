/* eslint-disable react/prop-types */
import React from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Image, Text, Title, List, Link, Divider, Box } from '../atoms';
import { truncateString } from '../../utils';

const format = text => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: data => {
        return <strong className="test-bold">{data}</strong>;
      },
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link color="link" href={node.data.uri} underline>
            {children}
          </Link>
        );
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <List>{children}</List>;
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <List as="ol">{children}</List>;
      },
      [BLOCKS.HR]: (node, children) => {
        return <Divider my={['xl', 56]}>{children}</Divider>;
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return (
          <Text size="xl" fontWeight="bold" as="blockquote" mx={['m', 'xl']}>
            {children}
          </Text>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <Text>{children}</Text>;
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <Title mb="l" mt="l" size="h3">
            {children}
          </Title>
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <Title mb="l" mt="l" size="h3">
            {children}
          </Title>
        );
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Title mb="l" mt="l" size="h4">
            {children}
          </Title>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => {
        if (fields && fields.file) {
          const { url } = fields.file['en-US'];
          const description = fields.description && fields.description['en-US'];
          return (
            <Box as="figure" className="img-fullwidth" my={56} textAlign="center">
              <picture>
                <source srcSet={`${url}?w=800`} media="(max-width: 768px)" />
                <source srcSet={`${url}?w=1600`} />
                <Image src={`${url}?w=1600`} loading="lazy" />
              </picture>
              {description && (
                <Text
                  as="figcaption"
                  textAlign="center"
                  size="m"
                  color="grayscale.800"
                  mt={28}
                  px={['m', 'l', 'xl']}
                >
                  {description}
                </Text>
              )}
            </Box>
          );
        }
        return <Text>Can&apos;t get image from cms.</Text>;
      },
    },
  };

  // console.log(documentToHtmlString(text));
  return documentToReactComponents(text, options);
};

const postDescription = text => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => {
        return `${next(node.content)} `;
      },
    },
  };

  return truncateString(documentToHtmlString(text, options), 130).trim();
};

export { format, postDescription };
