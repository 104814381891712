import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Flex, Box, Text, Image } from '../../atoms';
import { styledSpaces } from '../../../utils';
import avatarHB from '../../../assets/img/avatar-hb.png';

const Author = ({ date, author, ...rest }) => {
  const name = (author && author.name) || 'Heartbeat agency';
  const avatar = author && author.avatar && author.avatar.file.url;

  return (
    <Flex alignItems="center" {...styledSpaces(rest)}>
      {avatar ? (
        <Box mr="m" width={64} mt={4}>
          <Image fluid src={`${avatar}?w=128`} borderRadius="50%" />
        </Box>
      ) : (
        <Box mr="m" width={64} mt={4}>
          <Image fluid src={avatarHB} borderRadius="50%" />
        </Box>
      )}
      <Box>
        <div style={{ marginBottom: -2 }}>
          <span>{name}</span>
        </div>
        <Text color="grayscale.800" mb={0} fontSize={20} as="time">
          {moment(date).format('ll')}
        </Text>
      </Box>
    </Flex>
  );
};

Author.propTypes = {
  date: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default Author;
